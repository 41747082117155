exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-about-page-about-page-tsx": () => import("./../../../src/templates/aboutPage/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-about-page-tsx" */),
  "component---src-templates-content-content-tsx": () => import("./../../../src/templates/content/content.tsx" /* webpackChunkName: "component---src-templates-content-content-tsx" */),
  "component---src-templates-index-page-index-page-tsx": () => import("./../../../src/templates/indexPage/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-index-page-tsx" */),
  "component---src-templates-projects-page-projects-page-tsx": () => import("./../../../src/templates/projectsPage/projects-page.tsx" /* webpackChunkName: "component---src-templates-projects-page-projects-page-tsx" */),
  "component---src-templates-stack-page-stack-page-tsx": () => import("./../../../src/templates/stackPage/stack-page.tsx" /* webpackChunkName: "component---src-templates-stack-page-stack-page-tsx" */)
}

